import { SxProps, Theme } from '@mui/material';

export const helperText: SxProps<Theme> = {
  margin: 0,
  padding: 0,
  position: 'absolute',
  bottom: '30px',
  fontSize: '14px',
  color: 'rgb(98, 98, 98)',
};

export const helperTextAfter: SxProps<Theme> = {
  left: '440px',
  margin: 0,
  padding: 0,
  position: 'absolute',
  bottom: '30px',
  fontSize: '14px',
  color: 'rgb(98, 98, 98)',
};
export const helperTextAfterUnderstanding: SxProps<Theme> = {
  left: '375px',
  margin: 0,
  padding: 0,
  position: 'absolute',
  bottom: '30px',
  fontSize: '14px',
  color: 'rgb(98, 98, 98)',
};
export const helperTextMobile: SxProps<Theme> = {
  margin: 0,
  padding: 0,
};
