const practices = [
  'Abbotsbury Road Surgery',
  'Adam Practice ',
  'Alma Partnership',
  'Apples Medical Centre',
  'Barcellos Family Practice (Formerly Corbin Avenue Surgery)',
  'Barn Surgery',
  'Barton House Surgery',
  'Bearwood & The Banks Med Centre',
  'Beaufort Road Surgery',
  'Bere Regis Surgery',
  'Birchwood Medical Centre',
  'Blackmore Vale',
  'Boscombe Manor Med Centre',
  'Bridges Medical Centre',
  'Bridport Medical Centre',
  'Burton Med Centre',
  'Bute House Surgery',
  'Canford Heath Group Practice',
  'Carlisle House Surgery',
  'Cerne Abbas Surgery',
  'Charmouth Medical Practice',
  'Corfe Castle Surgery',
  'Cranborne Surgery',
  'Crescent Surgery',
  'Cross Road Surgery',
  'Denmark Road Med Centre',
  'Dorchester Road Surgery',
  'Durdells Avenue Surgery',
  'Eagle House Surgery',
  'Evergreen Oak Surgery',
  "Family Medical Services (Dr Newman's Surgery)",
  'Farmhouse Surgery',
  'Fordington Surgery',
  'Gillingham Barn Surgery',
  'Grove Surgery',
  'Hadleigh Practice',
  'Harvey Practice',
  'Heatherview Medical Centre',
  'Herbert Avenue Surgery',
  'Highcliffe Med Centre',
  'James Fisher Med Centre',
  'Kinson Road Med Centre',
  'Lanehouse Surgery',
  'Leybourne Surgery',
  'Lilliput Surgery',
  'Littledown Surgery',
  'Longfleet House Surgery',
  'Longfleet Road',
  'Lyme Bay Medical Practice',
  'Lyme Regis Medical Centre (Virgin HC) ',
  'Madeira Medical Centre',
  'Marine & Oakridge Pship',
  'Milton Abbas Surgery',
  'Moordown Med Centre',
  'Newland Surgery',
  'Northbourne Surgery',
  'Old Dispensary',
  'Orchard Surgery',
  'Orchid House Surgery',
  'Panton Practice',
  'Parkstone Health Centre',
  "Penny's Hill Practice",
  'Poole Road Medical Centre',
  'Poole Town Surgery',
  'Portesham Surgery',
  'Poundbury Doctors Surgery (Cornwall Road)',
  'Prince of Wales Surgery',
  'Providence Surgery',
  'Puddletown Surgery',
  'Quarterjack Surgery',
  'Queens Avenue Surgery',
  'Rosemary Medical Centre',
  'Royal Crescent Surgery',
  'Royal Manor Health Care',
  'Sandford Surgery',
  'Shelley Manor and Holdenhurst Med Centre',
  'Southbourne Surgery',
  'St Albans Med Centre',
  'Stalbridge Surgery',
  'Stour Surgery',
  'Swanage Medical Centre',
  'Talbot Med Centre',
  'The Atrium Health Centre ',
  'The Tollerford Practice',
  'Verwood Surgery',
  'Village Surgery',
  'Walford Mill Surgery',
  'Wareham Surgery',
  'Wellbridge Practice',
  'Wessex Road Surgery',
  'West Moors Group Practice',
  'Westbourne Medical Centre',
  'Whitecliff Group Practice',
  'Woodlea House Surgery',
  'Wyke Regis HC',
  'Yetminster Health Centre',
];

export default practices;
