import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Layout from '../layout/Layout';
import CustomButton from '../common/customButton/CustomButton';
import useScreenSize from '../../utils/mediaQuery';
import apiInstance from '../../utils/api';

function PasswordReset() {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | ReactNode>();
  const [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  const { isTablet, isMobile } = useScreenSize();

  const attemptPasswordReset = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const values = new FormData(event.currentTarget);
    const newPassword = values.get('password');
    const passwordConfirmation = values.get('confirm-password');

    setLoading(true);

    setTimeout(async () => {
      if (newPassword !== passwordConfirmation) {
        setPasswordMismatch(true);
        setMessage(<span>Your passwords do not match.</span>);
        setLoading(false);
      } else {
        try {
          const res = await apiInstance.post(
            `/auth/password-reset/`,
            {
              newPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (res.data.success) {
            setLoading(false);
            setMessage(
              <span>
                Your password has been reset. <Link to="/">Login here</Link>
              </span>
            );
            setSuccess(true);
            setLoading(false);
          }
          if (!res.data.success) {
            setLoading(false);
            setMessage(
              'Error validating token. Please reset your password again.'
            );
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
          setMessage('Error contacting server. Please try again later.');
        }
      }
    }, 1000);
  };

  return (
    <Layout title="request-password-reset">
      <Grid sx={isMobile ? { padding: '0 10px' } : {}}>
        <Typography sx={{ paddingTop: '20px' }} variant="h1">
          Create a new password
        </Typography>
        <Grid
          container
          style={isMobile ? { width: '95%' } : { width: '40%' }}
          direction="column"
          component="form"
          onSubmit={attemptPasswordReset}
        >
          <TextField
            sx={{ margin: '10px 0' }}
            type="password"
            name="password"
            id="password"
            label="Password"
            variant="outlined"
          />
          <TextField
            sx={{ margin: '10px 0' }}
            type="password"
            name="confirm-password"
            id="confirm-password"
            label="Confirm password"
            variant="outlined"
          />
          <CustomButton
            type="submit"
            style={{ margin: '10px 0' }}
            disabled={loading}
            buttonType="primaryButton"
          >
            <span>
              {loading ? (
                <CircularProgress style={{ marginLeft: 15 }} size={15} />
              ) : (
                'Update Password'
              )}
            </span>
          </CustomButton>
        </Grid>
        <Box sx={{ height: '50px', paddingBottom: '50px' }}>
          {message && (
            <Alert
              style={isMobile ? { width: '95%' } : { width: '40%' }}
              sx={{
                margin: '15px 0',
                padding: '6px 0',
              }}
              severity={success ? 'success' : 'warning'}
            >
              {message}
            </Alert>
          )}
        </Box>
      </Grid>
    </Layout>
  );
}

export default PasswordReset;
