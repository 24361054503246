import { useContext, useState } from 'react';
import {
  Alert,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../common/customButton/CustomButton';
import useScreenSize from '../../utils/mediaQuery';
import { UserStateContext } from '../../contexts/user-state-context/user-state.context';
import apiInstance from '../../utils/api';
import { UserStateProviderActionType } from '../../contexts/user-state-context/enums/user-state-provider-action-type.enum';

function LoginForm() {
  const navigate = useNavigate();
  const { isTablet, isMobile } = useScreenSize();
  const { dispatchToUser } = useContext(UserStateContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();

  if (loginSuccess && !loading) {
    navigate('/questionnaire');
  }

  const attemptSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const values = new FormData(event.currentTarget);
    const email = values.get('email');
    const password = values.get('password');

    setTimeout(async () => {
      try {
        const { data } = await apiInstance.post('/auth/login', {
          email,
          password,
        });
        // const accessObject = {
        //   token: data.token,
        //   expiresAt: new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
        // };
        const { token, userEmail, userId } = data;

        // sessionStorage.setItem('userToken', JSON.stringify(accessObject));
        // sessionStorage.setItem('userToken', JSON.stringify(data.token));
        sessionStorage.setItem('userToken', token);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userEmail', userEmail);
        dispatchToUser({
          type: UserStateProviderActionType.SET_USER_TOKEN,
          payload: token,
        });
        dispatchToUser({
          type: UserStateProviderActionType.SET_USER_ID,
          payload: userId,
        });
        dispatchToUser({
          type: UserStateProviderActionType.SET_USER_EMAIL,
          payload: userEmail,
        });
        setLoginSuccess(true);
      } catch (error: any) {
        console.log(error);
        setLoginSuccess(false);

        switch (error.message) {
          case 'Incorrect credentials':
            setMessage(
              'Some details you entered are not correct, please try again.'
            );
            break;
          default:
            setMessage('Unexpected Server error. Please try again later');
        }
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <Grid
      item
      sm={19}
      sx={{
        margin: isMobile ? '10px auto' : '0',
        bgcolor: '#CFF2D6',
        padding: '10px 25px 25px',
      }}
      component="form"
      onSubmit={attemptSignIn}
    >
      <Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle1">Welcome back</Typography>
          <Typography variant="subtitle2">Returning patient</Typography>
          <Typography variant="body1">
            If you have previously registered, you can return to any part of the
            programme by simply entering your email and password below.
          </Typography>
        </Stack>
        <TextField
          style={{ marginTop: '24px' }}
          required
          name="email"
          id="login-email"
          type="email"
          label="Email"
          variant="outlined"
        />
        <TextField
          required
          type="password"
          name="password"
          id="login-password"
          label="Password"
          variant="outlined"
        />
        <Stack spacing={2} sx={{ paddingBottom: '20px' }}>
          <FormControlLabel
            style={{ marginLeft: -10, padding: 0 }}
            control={<Checkbox name="rememberMeRegister" />}
            label="Remember me"
          />
          <CustomButton
            type="submit"
            disabled={loading}
            buttonType="primaryButton"
          >
            <span>
              {loading ? (
                <CircularProgress style={{ marginLeft: 15 }} size={15} />
              ) : (
                <>
                  <span>Login</span>
                  <LockIcon
                    sx={{ marginLeft: '10px', transform: 'translateY(3px)' }}
                  />
                </>
              )}
            </span>
          </CustomButton>
          <Link to="/request-password-reset">I've forgotten my password</Link>
        </Stack>
        <Box sx={{ height: '50px', paddingBottom: '50px' }}>
          {message && (
            <Alert
              style={{ width: '100%' }}
              sx={{
                margin: '15px 0',
                padding: '6px 5px',
              }}
              severity="warning"
            >
              {message}
            </Alert>
          )}
        </Box>
      </Stack>
    </Grid>
  );
}

export default LoginForm;
