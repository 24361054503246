import { SxProps, Theme } from '@mui/material';

export const telAndEmail: SxProps<Theme> = {
  margin: '5px 0px',
  height: '20px',
  textDecoration: 'none',
  display: 'block',
  color: '#1c5daf',
};

export const legal: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '40px 40px',
};
