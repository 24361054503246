import { SxProps, Theme } from '@mui/material';

export const primaryButtonStyles: SxProps<Theme> = {
  background: `linear-gradient(90deg, rgba(209, 72, 137, 1) 27%, rgba(82, 58, 136, 1) 97%)`,
  color: 'white',
  textTransform: 'capitalize',
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
};

export const nextButtonStyles: SxProps<Theme> = {
  background:
    'linear-gradient(90deg, rgb(68, 157, 68) 19%, rgb(82, 175, 82) 100%)',
  padding: '10px 40px',
  color: 'white',
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: 600,
  width: '150px',
  position: 'relative',
  right: 0,
};

export const backButtonStyles: SxProps<Theme> = {
  background: 'grey',
  padding: '10px 40px',
  color: 'white',
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: 600,
  width: '150px',
  position: 'relative',
  left: 0,
  '&:hover': {
    background: 'grey',
  },
};
