import { Grid, Link, Typography } from '@mui/material';

import medicalDecisionsLogo from '../../../assets/logos/logo.png';
import medicalConsentLogo from '../../../assets/logos/Medical_Consent_Logo_Transparent.png';
import useScreenSize from '../../../utils/mediaQuery';
import { legal, telAndEmail } from './footer.css';

function Footer() {
  const { isTablet, isMobile } = useScreenSize();
  return (
    <Grid
      sx={{
        fontFamily: 'museo-sans-rounded',
        padding: isTablet ? '30px 10px' : '30px 0',
        fontSize: isMobile || isTablet ? '12px' : '14px',
        lineHeight: 1.6,
        background:
          'linear-gradient(180deg,rgba(215, 219, 221, 1) 15%,rgba(255, 255, 255, 1) 100%)',
      }}
    >
      <Grid sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Grid
          container
          columns={{ xs: 2, sm: 12, md: 12 }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Grid item xs={1} sm={3} md={3}>
            <img
              src={medicalDecisionsLogo}
              alt="Medical Decisions"
              className="footer-logo-images"
              width={isMobile ? 100 : isTablet ? 120 : 190}
            />
          </Grid>
          <Grid item xs={1} sm={3} md={3}>
            <img
              src={medicalConsentLogo}
              alt="Medical Consent"
              className="footer-logo-images"
              width={isMobile ? 100 : isTablet ? 120 : 220}
            />
          </Grid>
          <Grid item xs={1} sm={3} md={3} className="address">
            <address>
              Dorset CCG
              <br />
              Canford House
              <br />
              551-553 Wallisdown Road
              <br />
              Poole
              <br />
              Dorset
              <br />
              BH12 5AG
            </address>
          </Grid>
          <Grid item xs={1} sm={3} md={3}>
            <Link sx={telAndEmail} href="tel:+44 (0)1202 798286">
              <strong style={{ color: 'black' }}>T.</strong> +44 (0)1202 798 286
            </Link>

            <Link sx={telAndEmail} href="mailto:support@medicalconsent.com">
              <strong style={{ color: 'black' }}>E.&nbsp;</strong>{' '}
              support@medicalconsent.co.uk
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                marginTop: '10px',
              }}
            >
              If you would like any further information on your condition please
              visit{' '}
              <a
                href="https://www.mskdorset.nhs.uk/"
                target="_blank"
                rel="noreferrer"
              >
                Looking after yourself | Musculoskeletal Matters
              </a>
              .
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',

                marginTop: '10px',
              }}
            >
              Please note the phone line and email address are for matters
              concerning this website only, and we cannot offer any clinical
              advice. For clinical advice, please contact your GP or physician.
              Thank you for your understanding.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <Grid sx={legal}>
              <p>
                Copyright &copy; Medical Decisions {new Date().getFullYear()}
              </p>
              <p>All rights reserved</p>
              <Link href="/privacy-policy">Privacy Policy</Link>
              <Link href="/terms-and-conditions">Terms and Conditions</Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
