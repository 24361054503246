import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    fontFamily: [
      'museo-sans-rounded',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  palette: {
    // primary: {
    //   light: "#a4ac86",
    //   main: "#656d4a",
    //   dark: "#414833",
    // },
    // primary: {
    //   light: '#976569',
    //   main: '#663336',
    //   dark: '#451619',
    // },
    // secondary: {
    //   light: '#6c757d',
    //   main: '#343a40',
    //   dark: '#212529',
    // },
    text: {
      primary: '#1c5daf',
      secondary: '#626262',
    },
    background: {
      default: '#eff8f8',
    },
  },
});

theme = createTheme(theme, {
  typography: {
    body1: {
      fontSize: 16,
      marginBottom: '10px',
      marginTop: '10px',
    },
    body2: {
      fontSize: 14,
    },
    h1: {
      fontSize: 36,
      paddingTop: '15px',
      marginBottom: 15,
      color: '#1c5daf',
      fontWeight: 500,
    },
    h2: {
      fontSize: 32,
      marginBottom: 15,
    },
    h3: {
      fontSize: 24,
      marginBottom: 15,
    },
    h4: {
      fontSize: 18,
      marginBottom: 15,
    },
    h5: {
      fontSize: 16,
      marginBottom: 15,
    },
    h6: {
      fontSize: 14,
      marginBottom: 15,
    },
    subtitle1: {
      fontSize: 28,
      fontWeight: 'bold',
      marginTop: 0,
      borderBottom: '2px solid white',
      paddingBottom: 5,
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 'bold',
    },
  },
  shape: {
    borderRadius: 3,
  },
  // Components

  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: 'solid lightgrey 2px',
          textTransform: 'none',
          fontSize: '18px',
          padding: '10px 20px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'grey',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'black',
          backgroundColor: 'white',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        sx: { minWidth: '170px' },
      },
    },
    MuiTableCell: {
      defaultProps: {
        align: 'center',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
