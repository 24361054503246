import { Box, Button, SxProps, Theme } from '@mui/material';
import React, { ReactChild } from 'react';
import {
  primaryButtonStyles,
  nextButtonStyles,
  backButtonStyles,
} from './customButton.css';

interface CustomButtonProps {
  buttonType: string;
  children: ReactChild | ReactChild[];
  disabled: boolean;
  type: 'submit' | 'reset' | 'button' | undefined;
  onClickFunction?: () => void;
  style?: Record<string, unknown>;
}

function CustomButton({
  buttonType,
  children,
  disabled,
  type,
  style,
  onClickFunction,
}: CustomButtonProps) {
  const sxProperty: SxProps<Theme> | undefined =
    buttonType === 'primaryButton'
      ? primaryButtonStyles
      : buttonType === 'next'
      ? nextButtonStyles
      : buttonType === 'back'
      ? backButtonStyles
      : undefined;
  return (
    <Button
      sx={sxProperty}
      disabled={disabled}
      onClick={onClickFunction}
      type={type}
      style={style}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
