import { createContext } from 'react';
import { UserStateProviderAction } from './interfaces/user-state-provider-action.interface';
import { UserState } from './interfaces/user-state.interface';

export const initialState: UserState = {
  userId: sessionStorage.getItem('userId')
    ? sessionStorage.getItem('userId')
    : null,
  userEmail: sessionStorage.getItem('userEmail')
    ? sessionStorage.getItem('userEmail')
    : null,
  userToken: null,
};

export const UserStateContext = createContext<{
  userState: UserState;
  dispatchToUser: (action: UserStateProviderAction) => void;
}>({
  userState: initialState,
  dispatchToUser: () => initialState,
});
