import Axios, { AxiosInstance, Method } from 'axios';

const apiInstance: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4000',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInstance.interceptors.request.use((config) => {
  console.log(process.env.REACT_APP_API_URL);
  const token = sessionStorage.getItem('userToken');

  if (config.headers) {
    if (
      token &&
      (!config.headers.Authorization || config.headers.Authorization === '')
    ) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
  }
  // This is executed on POST PUT PATCH AND DELETE METHODS
  // if (isMutableMethod(config.method)) {
  // Code to set up anything on our mutable request
  // }
  // console.log(config);
  return config;
});

apiInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger

    return response;
  },
  async (error) => {
    // const originalRequest = error.config;

    if (Axios.isCancel(error)) {
      return Promise.reject({ message: error.message, isCancel: true });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    switch (error.response.status) {
      case 400:
        console.log(error.response);
        return Promise.reject(error.response.data);

      case 401:
        console.log(error.response);
        return Promise.reject(error.response.data);

      case 403:
        console.log(error.response);
        return Promise.reject(error.response.data);

      case 404:
        console.log(error.response);
        return Promise.reject(error.response.data);

      case 409:
        console.log(error.response);
        return Promise.reject(error.response.data);

      case 500:
        console.log(error.response);
        return Promise.reject(error.response.data);

      default:
        console.log(error.response);
        return Promise.reject(error);
    }
  }
);

// const isMutableMethod = (requestMethod: Method | undefined) => {

//   switch (requestMethod) {
//     case "post":
//     case "put":
//     case "patch":
//     case "delete":
//       return true;

//     default:
//       return false;
//   }
// }

// const getCSRFToken = async () => {

//   try {
//     // Fetch a CSRF token to use with this session
//     const res = await apiInstance.get("/auth");

//     if (res.data.csrf) {

//       sessionStorage.setItem("csrf", res.data.csrf);
//     }
//     return res.data.csrf;
//   } catch (error) {
//     console.log(error);
//   }
// }

export default apiInstance;
