import { useState } from 'react';
import {
  Alert,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import CustomButton from '../common/customButton/CustomButton';
import Layout from '../layout/Layout';
import useScreenSize from '../../utils/mediaQuery';
import apiInstance from '../../utils/api';

function RequestPasswordReset() {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);

  const { isTablet, isMobile } = useScreenSize();

  const attemptRequestPasswordReset = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const values = new FormData(event.currentTarget);
    const email = values.get('email');
    setLoading(true);

    setTimeout(async () => {
      try {
        const res = await apiInstance.post('/auth/request-password-reset', {
          email,
        });

        if (res.data.success) {
          setSuccess(true);

          setMessage(
            'You should receive an email shortly with instructions to reset your password.'
          );
          setLoading(false);
        }
        if (!res.data.success) {
          setMessage(
            'We could not find a user associated to this email address.'
          );
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setMessage('Error contacting server. Please try again later.');
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <Layout title="request-password-reset">
      <Grid sx={isMobile ? { padding: '0 10px' } : {}}>
        <Typography sx={{ paddingTop: '20px' }} variant="h1">
          Request a new password
        </Typography>
        <Typography variant="body1">
          Tell us your registered email and we'll send you a link to reset it.
        </Typography>

        <Grid
          container
          style={isMobile ? { width: '100%' } : { width: '40%' }}
          direction="column"
          component="form"
          onSubmit={attemptRequestPasswordReset}
        >
          <TextField
            sx={{ margin: '10px 0' }}
            name="email"
            id="email"
            label="Email"
            variant="outlined"
          />
          <CustomButton
            type="submit"
            style={{ margin: '10px 0' }}
            disabled={loading}
            buttonType="primaryButton"
          >
            <span>
              {loading ? (
                <CircularProgress style={{ marginLeft: 15 }} size={15} />
              ) : (
                'Reset'
              )}
            </span>
          </CustomButton>
        </Grid>
        <Box sx={{ height: '50px', paddingBottom: '50px' }}>
          {message && (
            <Alert
              style={isMobile ? { width: '100%' } : { width: '40%' }}
              sx={{
                margin: '15px 0',
                padding: '6px 0',
              }}
              severity={success ? 'success' : 'warning'}
            >
              {message}
            </Alert>
          )}
        </Box>
      </Grid>
    </Layout>
  );
}

export default RequestPasswordReset;
