import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import RegisterAndLogIn from '../registerAndLogIn/RegisterAndLogIn';
// import Home from '../home';
import Home from '../home/Home';
import QuestionnairePage from '../questionnaire/Questionnaire';
import RequestPasswordReset from '../requestPasswordReset/RequestPasswordReset';
import PasswordReset from '../password-reset/PasswordReset';
import theme from '../../theme';
import Terms from '../terms/Terms';
import UserStateProvider from '../../contexts/user-state-context/user-state.provider';
import FormStateProvider from '../../contexts/form-state-context/form-state.provider';
import Instructional from '../instructional/Instructional';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserStateProvider>
        <FormStateProvider>
          <Router>
            <Routes>
              <Route path="/questionnaire" element={<QuestionnairePage />} />
              <Route
                path="/request-password-reset"
                element={<RequestPasswordReset />}
              />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/register" element={<RegisterAndLogIn />} />
              <Route path="/" element={<Home />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/instructional" element={<Instructional />} />
            </Routes>
          </Router>
        </FormStateProvider>
      </UserStateProvider>
    </ThemeProvider>
  );
}

export default App;
