import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './footer/Footer';
import Header from './header/Header';

interface LayoutProps {
  children: ReactNode;
  title: string;
}

function Layout({ children, title }: LayoutProps) {
  return (
    <>
      <Helmet>
        <title>
          {title ? `${title} | Dorset Decision Aids` : 'Dorset Decision Aids'}{' '}
        </title>
      </Helmet>
      <main>
        <Header />
        <Box sx={{ bgcolor: 'background.default' }}>
          <Box
            style={{
              paddingLeft: '5px',
              margin: '0 auto',
              maxWidth: '1200px',
            }}
          >
            {children}
          </Box>
        </Box>
        <Footer />
      </main>
    </>
  );
}

export default Layout;
