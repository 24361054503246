import { ReactNode } from 'react';
import useUserStateProvider from './hooks/use-user-state-provider';
import { UserStateContext } from './user-state.context';

interface Props {
  children: ReactNode;
}

const UserStateProvider = ({ children }: Props) => {
  const { userState, dispatchToUser } = useUserStateProvider();

  return (
    <UserStateContext.Provider value={{ userState, dispatchToUser }}>
      {children}
    </UserStateContext.Provider>
  );
};

export default UserStateProvider;
