import { useReducer } from 'react';
import { initialState } from '../form-state.context';
import { FormStateReducer } from '../form-state.reducer';

export default function useFormStateProvider() {
  const [formState, dispatchToForm] = useReducer(
    FormStateReducer,
    initialState
  );

  return {
    formState,
    dispatchToForm,
  };
}
