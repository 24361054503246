import { AnyRecord } from 'dns';
import { useContext, useState } from 'react';
import { FormStateContext } from '../../../contexts/form-state-context/form-state.context';
import apiInstance from '../../../utils/api';
import FormAnswers from '../interfaces/FormAnswers';

export default function useSubmitAnswers() {
  const { formState } = useContext(FormStateContext);
  const { formId } = formState;

  const [loading, setLoading] = useState<boolean>(false);
  const [formUpdateSuccess, setFormUpdateSuccess] = useState<boolean>(false);
  const [part, setPart] = useState<number>(1);

  const attemptSubmitAnswers = async (formAnswers: any) => {
    setLoading(true);

    // TODO - togglebuttons only work with strings which means I have to convert all the
    // non-string aspects of the DB to the correct type. Clealry this is a ñapa so
    // we should discuss what is the best option here

    if (formAnswers.confidence) {
      const correctAnswerOne = parseInt(formAnswers.confidence, 10);
      formAnswers.confidence = correctAnswerOne;
    }

    const correctAnswerTwo = parseInt(formAnswers.havingWatchedTheVideo, 10);
    formAnswers.havingWatchedTheVideo = correctAnswerTwo;

    formAnswers.foundThisHelpful =
      formAnswers.foundThisHelpful === 'yes'
        ? true
        : formAnswers.foundThisHelpful === 'no'
        ? false
        : null;

    const correctAnswerThree = parseInt(
      formAnswers.HWTVconfidenceManageSymptoms,
      10
    );
    formAnswers.HWTVconfidenceManageSymptoms = correctAnswerThree;

    const correctAnswerFour = parseInt(
      formAnswers.HWTVunderstandingCondition,
      10
    );
    formAnswers.HWTVunderstandingCondition = correctAnswerFour;

    setTimeout(async () => {
      try {
        const res = await apiInstance.put(`/forms/${formId}`, {
          ...formAnswers,
        });
        if (res.status === 200) {
          setFormUpdateSuccess(true);
          if (part === 1) {
            setPart(2);
          } else if (part === 2) {
            setPart(3);
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }, 500);
  };

  return {
    attemptSubmitAnswers,
    formUpdateSuccess,
    part,
    setPart,
    loading,
  };
}
