/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';
import PlayButton from '../../assets/icons/play-button.png';
import replayButton from '../../assets/icons/replay-button.png';

import Layout from '../layout/Layout';
import '../video/video.css';
import useScreenSize from '../../utils/mediaQuery';

const videoArea = {
  background:
    'linear-gradient(180deg,rgba(209, 72, 137, 1) 27%,rgba(82, 58, 136, 1) 97%)',
  width: '100%',
  height: '100%',
  position: 'relative',
  paddingBottom: '56.25%' /* 16:9 */,
  display: 'block',
  overflow: 'hidden',
  cursor: 'pointer',
};

const videoEndedOptions = {
  zIndex: '2',
  transition: 'all 0.5s linear',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const videoTitleStyles = {
  width: '80%',
  textAlign: 'center',
  fontSize: '1.8em',
  lineHeight: '6vw',
  fontWeight: 'bold',
  color: '#fff',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '3vw',
  margin: '0 auto',
};
const videoPlay = {
  position: 'absolute',
  zIndex: '2',
  margin: 'auto',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function Instructional() {
  const [player, setPlayer] = useState<any>();
  const [videoState, setVideoState] = useState<number>(-1);
  const [videoWatched, setVideoWatched] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(true);

  const { isTablet, isMobile } = useScreenSize();

  const videoPlayInstructions = {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    color: '#fff',
    fontWeight: '500',
    fontSize: isMobile ? '1em' : '2.5em',
    bottom: '5vw',
  };

  function handleReady(event: any) {
    setVideoLoaded(true);
    setPlayer(event.target);
  }

  function handleStateChange(event: any) {
    const playerState = event.target.getPlayerState();
    if (playerState === 0) {
      setVideoState(0);
      setVideoWatched(true);
    }
  }

  const play = () => {
    player.playVideo();
    setVideoState(1);
  };

  const replay = () => {
    player.playVideo();
    setVideoState(1);
  };

  const opts = {
    height: '100%',
    width: '100%',
  };

  return (
    <Layout title="Instructional">
      <Grid
        sx={{
          padding: '40px 0px',
        }}
      >
        <Grid sx={videoArea}>
          {!videoWatched && videoLoaded && videoState === -1 && (
            <>
              <Grid sx={videoPlay} onClick={play}>
                <img alt="" style={{ width: '15vw' }} src={PlayButton} />
              </Grid>
              <Grid sx={videoPlayInstructions}>Press play to get started</Grid>
            </>
          )}
          {videoState === 0 ||
            (videoState === -1 && (
              <Grid
                sx={videoTitleStyles}
                style={{
                  fontSize: isMobile ? '1em' : isTablet ? '3em' : '',
                }}
              >
                Instructional Video
              </Grid>
            ))}
          {videoWatched && videoState !== 1 && (
            <Grid sx={videoEndedOptions}>
              <img
                src={replayButton}
                alt="Replay Video"
                onClick={replay}
                style={{
                  width: isMobile ? '8em' : isTablet ? '12em' : '20em',
                  margin: '0 3vw',
                  cursor: 'pointer',
                }}
              />
            </Grid>
          )}{' '}
          <YouTube
            opts={opts}
            videoId="OnN2esE0w8s"
            onReady={(event) => handleReady(event)}
            onStateChange={(event) => handleStateChange(event)}
            className="video"
            containerClassName={`video-wrapper ${
              videoState === 0 || videoState === -1 ? '' : 'active'
            }`}
          />
        </Grid>
      </Grid>
      <Typography
        // variant="body1"
        sx={{
          fontSize: '18px',
          marginBottom: '0',
          paddingBottom: '20px',
          fontWeight: '500',
        }}
      >
        <Link
          style={{
            color: '#1c5daf',
            textDecoration: 'none',
          }}
          to="/"
        >
          Click here to return to the home page
        </Link>
      </Typography>
    </Layout>
  );
}

export default Instructional;
