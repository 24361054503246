import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import practices from '../../../utils/clinicList';
import useSubmitAnswers from '../hooks/useSubmitAnswers';
import FormAnswers from '../interfaces/FormAnswers';
import HelperText from '../../helperText/HelperText';
import CustomButton from '../../common/customButton/CustomButton';
import { FormStateContext } from '../../../contexts/form-state-context/form-state.context';
import useScreenSize from '../../../utils/mediaQuery';
import { control, controlMobile, label } from '../part.css';
import QuestionNumber from '../../questionNumber/QuestionNumber';

interface PartProps {
  procedure: string | null;
  setActivePart: (partNumber: number) => void;
}

function Part({ procedure, setActivePart }: PartProps) {
  const { formState } = useContext(FormStateContext);
  const { form } = formState;
  const [formAnswers, setFormAnswers] = useState<FormAnswers>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { attemptSubmitAnswers, formUpdateSuccess, loading } =
    useSubmitAnswers();
  const { isTablet, isMobile } = useScreenSize();

  const procedureTitle =
    procedure === 'knee'
      ? 'Total Knee Replacement Surgery'
      : procedure === 'hip'
      ? 'Total Hip Replacement Surgery'
      : procedure === 'shoulder'
      ? 'Shoulder Surgery - Rotator Cuff'
      : '';

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormAnswers({
      ...formAnswers,
      [name]: value,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (form) {
        // this is the because the same reason  as the ñapa in useSubmitAnswers
        const newform = JSON.parse(JSON.stringify(form));
        if (newform.howConfidentAreYou) {
          const correctFormAnswer = newform.howConfidentAreYou.toString();
          newform.howConfidentAreYou = correctFormAnswer;
          setFormAnswers(newform);
        }
        if (newform.confidence) {
          const correctFormAnswer = newform.confidence.toString();
          newform.confidence = correctFormAnswer;
          setFormAnswers(newform);
        }
        setFormAnswers(newform);
        // below line required once above fix is ready
        // setFormAnswers(JSON.parse(form));
      }
    }, 200);
  }, []);

  const CustomValidationsArray = [
    'whichBestExplainsWhoYouAre',
    'whoHasReferredYouToThisSite',
    'howConfidentAreYou',
    'understandingCondition',
    'describeActivityLevels',
    'confidence',
    'areYouASmoker',
    'wouldYouViewYourselfAs',
  ];

  const submitForm = (event: any) => {
    event.preventDefault();
    const newFormArray = Object.entries(formAnswers);
    const answersArray: string[] = [];
    newFormArray.forEach((question: any) => {
      if (CustomValidationsArray.includes(question[0])) {
        if (question[1] === '' || question[1] === null) {
          setErrorMessage(
            'You missed some answers, please check and try again'
          );
        } else {
          answersArray.push(question[1]);
        }
      }
    });
    if (CustomValidationsArray.length === answersArray.length) {
      attemptSubmitAnswers(formAnswers);
    }
  };

  if (formUpdateSuccess) {
    setActivePart(2);
  }

  return (
    <>
      <Grid
        container
        style={isMobile ? { padding: '0 5px' } : {}}
        direction="column"
      >
        {procedure?.includes('back') ? (
          <>
            <Typography variant="h1">
              Expectations for back health improvements.
            </Typography>
            <Typography variant="body1" style={{ fontSize: '17px' }}>
              These questions are designed to measure your expectations of this
              course to get you back to fitness. Please answer them as honestly
              as you can before you proceed any further in the programme.
            </Typography>
            <Typography variant="body1" style={{ fontSize: '17px' }}>
              After you have completed this course, you wil will have a chance
              to rate yourself again on these questions which will help us
              determine how much benefit you got from this course.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h1">
              Expectations for a “New{' '}
              <span style={{ textTransform: 'capitalize' }}>{procedure}</span>”
              following {procedureTitle}
            </Typography>
            <Typography variant="body1">
              These questions are designed to measure your expectations of
              undergoing {procedure} surgery. Please answer them, as honestly as
              you can, before you proceed any further in the programme.
            </Typography>
            <Typography variant="body1">
              Don’t worry that your answers might be different, later, after you
              have had the chance to consider all the information that we shall
              be giving you. Indeed, if your answers do change as a result of
              completing this programme, that will help us to determine whether
              or not the programme is actually of benefit to patients such as
              you.
            </Typography>
          </>
        )}
      </Grid>
      <Grid
        container
        style={isMobile ? { padding: '0 5px' } : { margin: 0 }}
        direction="column"
        component="form"
        onSubmit={submitForm}
      >
        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="whichBestExplainsWhoYouAre">
            <QuestionNumber>1</QuestionNumber>Which best explains who you are?
          </FormLabel>
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.whichBestExplainsWhoYouAre}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton
              name="whichBestExplainsWhoYouAre"
              value="I am a patient living in Dorset"
            >
              I am a patient living in Dorset
            </ToggleButton>
            <ToggleButton
              name="whichBestExplainsWhoYouAre"
              value="I am a patient living outside of Dorset"
            >
              I am a patient living outside of Dorset
            </ToggleButton>
            <ToggleButton
              name="whichBestExplainsWhoYouAre"
              value="I am an interested party"
            >
              I am an interested party
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <Divider />
        {formAnswers.whichBestExplainsWhoYouAre ===
        'I am a patient living in Dorset' ? (
          <div>
            <FormControl sx={isMobile ? controlMobile : control} required>
              <FormLabel sx={label} id="nameOfYourPractice">
                Please select the name of your practice below:
              </FormLabel>
              <Select
                sx={{ display: 'flex' }}
                labelId=""
                name="nameOfYourPractice"
                id=""
                value={formAnswers.nameOfYourPractice}
                onChange={handleChange}
              >
                {practices.map((practice) => {
                  return (
                    <MenuItem key={practice} value={practice}>
                      {practice}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Divider />
          </div>
        ) : null}
        {formAnswers.whichBestExplainsWhoYouAre ===
        'I am an interested party' ? (
          <>
            <FormControl sx={isMobile ? controlMobile : control} required>
              <FormLabel
                sx={label}
                id="interestedInWatchingThisVideoButNotAPatient"
              >
                I am interested in watching this video as I am a...
              </FormLabel>
              <ToggleButtonGroup
                orientation={`${isMobile ? `vertical` : `horizontal`}`}
                color="primary"
                value={formAnswers.interestedInWatchingThisVideoButNotAPatient}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton
                  name="interestedInWatchingThisVideoButNotAPatient"
                  value="Consultant"
                >
                  Consultant
                </ToggleButton>
                <ToggleButton
                  name="interestedInWatchingThisVideoButNotAPatient"
                  value="GP"
                >
                  GP
                </ToggleButton>
                <ToggleButton
                  name="interestedInWatchingThisVideoButNotAPatient"
                  value="Physiotherapist"
                >
                  Physiotherapist
                </ToggleButton>
                <ToggleButton
                  name="interestedInWatchingThisVideoButNotAPatient"
                  value="Administrator"
                >
                  Administrator
                </ToggleButton>
                <ToggleButton
                  name="interestedInWatchingThisVideoButNotAPatient"
                  value="Relative/Friend"
                >
                  Relative/Friend
                </ToggleButton>
                <ToggleButton
                  name="interestedInWatchingThisVideoButNotAPatient"
                  value="Other"
                >
                  Other
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            <Divider />
          </>
        ) : null}
        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="whoHasReferredYouToThisSite">
            <QuestionNumber>2</QuestionNumber>Who has referred you to this site?
          </FormLabel>
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.whoHasReferredYouToThisSite}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton name="whoHasReferredYouToThisSite" value="GP">
              GP
            </ToggleButton>
            <ToggleButton
              name="whoHasReferredYouToThisSite"
              value="Physiotherapist"
            >
              Physiotherapist
            </ToggleButton>
            <ToggleButton
              name="whoHasReferredYouToThisSite"
              value="Hospital Consultant"
            >
              Hospital Consultant
            </ToggleButton>
            <ToggleButton name="whoHasReferredYouToThisSite" value="Friend">
              Friend
            </ToggleButton>
            <ToggleButton name="whoHasReferredYouToThisSite" value="Family">
              Family
            </ToggleButton>
            <ToggleButton name="whoHasReferredYouToThisSite" value="Other">
              Other
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <Divider />

        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="howConfidentAreYou">
            <QuestionNumber>3</QuestionNumber>
            {procedure === 'back' ? (
              <span>
                How confident are you that this is the right treatment for you?
              </span>
            ) : (
              <span>How confident are you in needing surgery?</span>
            )}
          </FormLabel>
          <HelperText text="Not at all confident" type="before" />
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.howConfidentAreYou}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton name="howConfidentAreYou" value="1">
              1
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="2">
              2
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="3">
              3
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="4">
              4
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="5">
              5
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="6">
              6
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="7">
              7
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="8">
              8
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="9">
              9
            </ToggleButton>
            <ToggleButton name="howConfidentAreYou" value="10">
              10
            </ToggleButton>
          </ToggleButtonGroup>
          <HelperText text="Very confident" type="after" />
        </FormControl>

        <Divider />
        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="understandingCondition">
            <QuestionNumber>4</QuestionNumber>How well do you feel you
            understand your condition and any current treatment (including your
            diagnosis and medication)?
          </FormLabel>
          <HelperText text="No understanding" type="before" />
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.understandingCondition}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton name="understandingCondition" value="1">
              1
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="2">
              2
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="3">
              3
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="4">
              4
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="5">
              5
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="6">
              6
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="7">
              7
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="8">
              8
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="9">
              9
            </ToggleButton>
            <ToggleButton name="understandingCondition" value="10">
              10
            </ToggleButton>
            <HelperText text="Complete understanding" type="after" />
          </ToggleButtonGroup>
        </FormControl>
        <Divider />
        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="confidence">
            <QuestionNumber>5</QuestionNumber>
            {procedure === 'back' ? (
              <span>
                How confident have you felt in being able to manage your back
                pain by yourself (e.g. medication, changing lifestyle)?
              </span>
            ) : (
              <span>
                How confident have you felt in being able to manage your joint
                or muscle symptoms by yourself (e.g. medication, changing
                lifestyle)?
              </span>
            )}
          </FormLabel>
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.confidence}
            exclusive
            onChange={handleChange}
          >
            <HelperText text="Not at all confident" type="before" />
            <ToggleButton name="confidence" value="1">
              1
            </ToggleButton>
            <ToggleButton name="confidence" value="2">
              2
            </ToggleButton>
            <ToggleButton name="confidence" value="3">
              3
            </ToggleButton>
            <ToggleButton name="confidence" value="4">
              4
            </ToggleButton>
            <ToggleButton name="confidence" value="5">
              5
            </ToggleButton>
            <ToggleButton name="confidence" value="6">
              6
            </ToggleButton>
            <ToggleButton name="confidence" value="7">
              7
            </ToggleButton>
            <ToggleButton name="confidence" value="8">
              8
            </ToggleButton>
            <ToggleButton name="confidence" value="9">
              9
            </ToggleButton>
            <ToggleButton name="confidence" value="10">
              10
            </ToggleButton>
            <HelperText text="Very confident" type="after" />
          </ToggleButtonGroup>
        </FormControl>
        <Divider />
        <Typography
          variant="subtitle1"
          sx={{
            margin: '15px 0 5px',
            color: 'grey',
            fontWeight: 300,
            fontSize: '28px',
            borderBottom: '2px solid black',
          }}
        >
          OTHER QUESTIONS
        </Typography>
        {procedure !== 'back' && (
          <Typography
            sx={{
              fontSize: '20px',
              marginBottom: '5px',
            }}
            variant="body1"
          >
            When considering your readiness for surgery, as well as your
            symptoms - please answer the lifestyle questions below:
          </Typography>
        )}
        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="describeActivityLevels">
            <QuestionNumber>6</QuestionNumber>How would you describe your
            fitness/activity levels?
          </FormLabel>
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.describeActivityLevels}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton name="describeActivityLevels" value="inactive">
              Inactive
            </ToggleButton>
            <ToggleButton name="describeActivityLevels" value="mildly active">
              Mildly active
            </ToggleButton>
            <ToggleButton
              name="describeActivityLevels"
              value="moderately active"
            >
              Moderately active
            </ToggleButton>
            <ToggleButton name="describeActivityLevels" value="very active">
              Very active
            </ToggleButton>
            <ToggleButton
              name="describeActivityLevels"
              value="extremely active"
            >
              Extremely active
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>

        {formAnswers.describeActivityLevels &&
        (formAnswers.describeActivityLevels === 'inactive' ||
          formAnswers.describeActivityLevels === 'mildly active') ? (
          <p className="info-text">
            You can visit Livewell Dorset to find out more about getting active.
            Links to the site are available at the end of this process.
          </p>
        ) : null}
        <Divider />
        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="areYouASmoker">
            <QuestionNumber>7</QuestionNumber>Do you smoke?
          </FormLabel>
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.areYouASmoker}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton name="areYouASmoker" value="yes">
              Yes
            </ToggleButton>
            <ToggleButton name="areYouASmoker" value="no">
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>

        {formAnswers.areYouASmoker && formAnswers.areYouASmoker === 'yes' ? (
          <p className="info-text">
            You can visit Livewell Dorset to find out more about quitting
            smoking. Links to the site are available at the end of this process.
          </p>
        ) : null}
        <Divider />

        <FormControl sx={isMobile ? controlMobile : control} required>
          <FormLabel sx={label} id="wouldYouViewYourselfAs">
            <QuestionNumber>8</QuestionNumber>Would you view yourself as?
          </FormLabel>
          <ToggleButtonGroup
            orientation={`${isMobile ? `vertical` : `horizontal`}`}
            color="primary"
            value={formAnswers.wouldYouViewYourselfAs}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton name="wouldYouViewYourselfAs" value="underweight">
              Underweight
            </ToggleButton>
            <ToggleButton
              name="wouldYouViewYourselfAs"
              value="mildly underweight"
            >
              Mildly underweight
            </ToggleButton>
            <ToggleButton name="wouldYouViewYourselfAs" value="normal weight">
              Normal weight
            </ToggleButton>
            <ToggleButton
              name="wouldYouViewYourselfAs"
              value="mildly overweight"
            >
              Mildly overweight
            </ToggleButton>
            <ToggleButton name="wouldYouViewYourselfAs" value="overweight">
              Overweight
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        {formAnswers.wouldYouViewYourselfAs &&
        formAnswers.wouldYouViewYourselfAs !== 'normal weight' ? (
          <p className="info-text">
            You can visit Livewell Dorset to find out more about weight
            management. Links to the site are available at the end of this
            process.
          </p>
        ) : null}
        <Divider />
        <Grid
          //
          style={{
            padding: '40px 0 60px',
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Grid
            container
            direction="column"
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
            }}
          >
            <CustomButton
              type="submit"
              // disabled
              disabled={loading}
              buttonType="next"
            >
              <span>
                {loading ? (
                  <CircularProgress style={{ marginLeft: 15 }} size={15} />
                ) : (
                  'Next'
                )}
              </span>
            </CustomButton>
            <Typography variant="body2" sx={{ color: 'red', marginTop: '5px' }}>
              {errorMessage}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Part;
