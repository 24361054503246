import { useMediaQuery } from '@mui/material';

const useScreenSize = () => {
  const isMobile = useMediaQuery('(max-width:1200px)');
  const isTablet = useMediaQuery('(max-width:1200px)');

  return {
    isMobile,
    isTablet,
  };
};

export default useScreenSize;
