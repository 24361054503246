import { Reducer } from 'react';
import { UserStateProviderActionType } from './enums/user-state-provider-action-type.enum';
import { initialState } from './user-state.context';
import { UserStateProviderAction } from './interfaces/user-state-provider-action.interface';
import { UserState } from './interfaces/user-state.interface';

export const UserStateReducer: Reducer<UserState, UserStateProviderAction> = (
  userState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case UserStateProviderActionType.RESET_STATE:
      return initialState;

    case UserStateProviderActionType.SET_USER_TOKEN:
      return { ...userState, userToken: payload };

    case UserStateProviderActionType.SET_USER_ID:
      return { ...userState, userId: payload };

    case UserStateProviderActionType.SET_USER_EMAIL:
      return { ...userState, userEmail: payload };

    default:
      return userState;
  }
};
