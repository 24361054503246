import { Typography } from '@mui/material';
import useScreenSize from '../../utils/mediaQuery';
import {
  helperText,
  helperTextAfter,
  helperTextAfterUnderstanding,
  helperTextMobile,
} from './helperText.css';

interface HelperTextProps {
  type: string;
  text: string;
}

function HelperText({ type, text }: HelperTextProps) {
  const { isTablet, isMobile } = useScreenSize();
  return (
    <Typography
      variant="body2"
      sx={
        isMobile
          ? helperTextMobile
          : type === 'before'
          ? helperText
          : type === 'after' && text === 'Complete understanding'
          ? helperTextAfterUnderstanding
          : type === 'after'
          ? helperTextAfter
          : {}
      }
    >
      {text}
    </Typography>
  );
}

export default HelperText;
