import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Container } from '@mui/material';
import Cookies from 'js-cookie';
import Layout from '../layout/Layout';
import Part from '../part/partOne/Part';
import PartTwo from '../part/partTwo/PartTwo';
import Results from '../results/Results';
import StatusBar from '../statusBar/StatusBar';
import { UserStateContext } from '../../contexts/user-state-context/user-state.context';
import { FormStateContext } from '../../contexts/form-state-context/form-state.context';
import useScreenSize from '../../utils/mediaQuery';
import { FormStateProviderActionType } from '../../contexts/form-state-context/enums/form-state-provider-action-type.enum';
import apiInstance from '../../utils/api';

function QuestionnairePage() {
  const { isTablet } = useScreenSize();
  const { userState } = useContext(UserStateContext);
  const { formState, dispatchToForm } = useContext(FormStateContext);
  const [loading, setLoading] = useState<boolean>(true);

  const [activePart, setActivePart] = useState(1);

  const { userId } = userState;
  const { formProcedure } = formState;

  async function getUserForm(userId: string | null, procedure: string | null) {
    setTimeout(async () => {
      try {
        const initialFormResponse = await apiInstance.get(
          `users/${userId}/forms?procedure=${procedure}`
        );
        const latestForm = initialFormResponse.data.data;

        if (!latestForm) {
          const newFormResponse = await apiInstance.post(`/forms`, {
            userId,
            procedure,
            formName: '0-1m',
          });

          const form = newFormResponse.data.data;
          const formId = newFormResponse.data.data.id;
          sessionStorage.setItem('form', JSON.stringify(form));
          sessionStorage.setItem('formId', formId);
          dispatchToForm({
            type: FormStateProviderActionType.SET_FORM,
            payload: form,
          });
          dispatchToForm({
            type: FormStateProviderActionType.SET_FORM_ID,
            payload: formId,
          });
          setLoading(false);
        } else {
          const form = initialFormResponse.data.data;
          const formId = initialFormResponse.data.data.id;
          sessionStorage.setItem('form', JSON.stringify(form));
          sessionStorage.setItem('formId', formId);

          dispatchToForm({
            type: FormStateProviderActionType.SET_FORM,
            payload: form,
          });

          dispatchToForm({
            type: FormStateProviderActionType.SET_FORM_ID,
            payload: formId,
          });

          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  }

  useEffect(() => {
    getUserForm(userId, formProcedure);
    if (Cookies.get('processCompleted') === formProcedure) {
      setActivePart(3);
    }
  }, [userId, formProcedure]);
  // }, [userId]);
  // }, [formProcedure]);

  if (!sessionStorage.getItem('userToken')) {
    return <Navigate to="/" />;
  }

  return (
    <Layout title="questionnaire">
      {activePart !== 3 && <StatusBar activePart={activePart} />}
      <Container
        sx={{
          marginTop: 2,
          padding: isTablet ? '0 10px' : '',
          bgColor: '#eff8f8',
        }}
        disableGutters
      >
        {loading && (
          <div>
            <CircularProgress style={{ marginLeft: 15 }} size={15} />
          </div>
        )}
        {!loading && activePart === 1 && (
          <Part procedure={formProcedure} setActivePart={setActivePart} />
        )}
        {!loading && activePart === 2 && (
          <div>
            <PartTwo procedure={formProcedure} setActivePart={setActivePart} />
          </div>
        )}
        {!loading &&
          (activePart === 3 ||
            Cookies.get('processCompleted') === formProcedure) && (
            <Results procedure={formProcedure} />
          )}
      </Container>
    </Layout>
  );
}

export default QuestionnairePage;
