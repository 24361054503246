import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface QuestionNumberProps {
  children: ReactNode;
}

const QuestionNumberStyles: SxProps<Theme> = {
  width: '28px',
  height: '28px',
  border: '2px solid rgb(0, 63, 143)',
  borderRadius: '50%',
  display: ' inline-flex',
  margin: '0 10px',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgb(0, 63, 143)',
  fontSize: '18px',
  fontWeight: 'bold',
};

function QuestionNumber({ children }: QuestionNumberProps) {
  return (
    <Box sx={QuestionNumberStyles} component="span">
      {children}
    </Box>
  );
}

export default QuestionNumber;
