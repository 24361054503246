import { ReactNode } from 'react';
import useFormStateProvider from './hooks/use-form-state-provider';
import { FormStateContext } from './form-state.context';

interface Props {
  children: ReactNode;
}

const FormStateProvider = ({ children }: Props) => {
  const { formState, dispatchToForm } = useFormStateProvider();

  return (
    <FormStateContext.Provider value={{ formState, dispatchToForm }}>
      {children}
    </FormStateContext.Provider>
  );
};

export default FormStateProvider;
