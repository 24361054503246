import { useReducer } from 'react';
import { initialState } from '../user-state.context';
import { UserStateReducer } from '../user-state.reducer';

export default function useUserStateProvider() {
  const [userState, dispatchToUser] = useReducer(
    UserStateReducer,
    initialState
  );

  return {
    userState,
    dispatchToUser,
  };
}
