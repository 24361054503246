import { createContext } from 'react';
import { FormStateProviderAction } from './interfaces/form-state-provider-action.interface';
import { FormState } from './interfaces/form-state.interface';

export const initialState: FormState = {
  formId: sessionStorage.getItem('formId')
    ? sessionStorage.getItem('formId') || ''
    : null,
  form: sessionStorage.getItem('form')
    ? sessionStorage.getItem('form') || ''
    : null,
  formProcedure: sessionStorage.getItem('formProcedure')
    ? sessionStorage.getItem('formProcedure') || ''
    : null,
  formProcedureName: sessionStorage.getItem('formProcedureName')
    ? sessionStorage.getItem('formProcedureName') || ''
    : null,
};

export const FormStateContext = createContext<{
  formState: FormState;
  dispatchToForm: (action: FormStateProviderAction) => void;
}>({
  formState: initialState,
  dispatchToForm: () => initialState,
});
