/* eslint-disable jsx-a11y/alt-text */
import { Container, Typography } from '@mui/material';

import lwd from '../../assets/images/lwd.png';
import msk from '../../assets/images/msk.png';
import stepstowellbeing from '../../assets/images/stepstowellbeing.png';

interface ResultsProps {
  procedure: string | null;
}

function Results({ procedure }: ResultsProps) {
  return (
    <Container sx={{ paddingBottom: '30px' }} className="results">
      <Typography variant="h1">Questionnaire complete</Typography>
      {procedure?.includes('back') ? (
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          Thank you for taking part in our Back to Fitness programme.
        </Typography>
      ) : (
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          Thank you for taking part in our patient decision aid for those
          patients considering {procedure} surgery.
        </Typography>
      )}

      <Typography variant="body1">
        If you have watched this video, you are likely to be suffering with{' '}
        {procedure && (procedure === 'hip' || procedure === 'knee')
          ? `osteoarthritis of the ${procedure}`
          : procedure === 'shoulder'
          ? 'rotator cuff related shoulder pain'
          : 'back pain'}{' '}
        and are maybe considering what steps you can take to help yourself and,
        if there is help to support you with this.
      </Typography>
      <Typography variant="body1">
        As explained, losing weight, stopping smoking, and performing more
        physical activity (or even becoming less sedentary) is very likely to
        improve your symptoms, your function, and your wellbeing. If you do
        require surgery, they will also significantly benefit your preparation
        for surgery. Your risks of surgery will reduce, your immediate recovery
        will improve, and your longer-term outcomes will be better too.
      </Typography>
      <Typography variant="body1">
        <strong>Live Well Dorset</strong> is a free service that can advise and
        help you get on the right track as well as give you support along the
        way. Please make contact via phone on{' '}
        <strong>0800 840 1628 or 01305 233 105</strong> Monday - Friday 9am -
        6:30pm or through
        <strong>
          {' '}
          <a
            href="https://www.livewelldorset.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            their website
          </a>
          .
        </strong>
      </Typography>
      <a
        style={{ margin: '30px 0', display: 'block' }}
        href="https://www.livewelldorset.co.uk/"
        target="_blank"
        rel="noreferrer"
      >
        <img width={220} src={lwd} />
      </a>
      {procedure?.includes('back') ? (
        <Typography variant="body1">
          More information and advice on how to manage back pain can be found on
          the back pain section of the{' '}
          <strong>
            {' '}
            <a
              href="https://www.mskdorset.nhs.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Dorset MSK Matters website
            </a>
            .{' '}
          </strong>
        </Typography>
      ) : (
        <Typography variant="body1">
          Strengthening exercises (in addition to weight loss and increasing
          your physical activity levels) will have similar impact and further
          information can be found in the{' '}
          {procedure && (procedure === 'hip' || procedure === 'knee')
            ? `${procedure} osteoarthritis`
            : 'rotator cuff related shoulder pain'}{' '}
          section of the
          <strong>
            {' '}
            <a
              href="https://www.mskdorset.nhs.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Dorset MSK Matters website
            </a>
            .{' '}
          </strong>
          It is also the route of self-referral to physiotherapy if you need
          additional help and advice.
        </Typography>
      )}

      <a
        style={{ margin: '30px 0', display: 'block' }}
        href="https://www.mskdorset.nhs.uk/"
        target="_blank"
        rel="noreferrer"
      >
        <img width={220} src={msk} />
      </a>
      <Typography variant="body1">
        Living with pain can be a stressful experience and, stress can increase
        the pain levels we experience.
        <strong>
          {' '}
          <a
            href="https://www.steps2wellbeing.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            Steps to Wellbeing
          </a>
        </strong>{' '}
        can help you in a variety of ways with this and further information and
        self-referral into these services can be found by accessing{' '}
        <strong>
          <a
            href="https://www.steps2wellbeing.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            their website
          </a>
          .
        </strong>
      </Typography>
      <a
        style={{ margin: '30px 0', display: 'block' }}
        href="https://www.steps2wellbeing.co.uk/"
        target="_blank"
        rel="noreferrer"
      >
        <img width={220} src={stepstowellbeing} />
      </a>
      {procedure?.includes('back') ? (
        <Typography variant="body1">
          If you feel you haven’t benefited from this service we would recommend
          that you now return to see the medical professional who referred you
          to this service.
        </Typography>
      ) : (
        <Typography variant="body1">
          If you are still considering {procedure} surgery we would recommend
          that you now return to see the medical professional who referred you
          to this service.
        </Typography>
      )}
      <Typography variant="body1">
        We hope you enjoyed the experience and have found this service useful
        and easy to use.
      </Typography>
    </Container>
  );
}

export default Results;
