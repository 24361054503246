import { Reducer } from 'react';
import { FormStateProviderActionType } from './enums/form-state-provider-action-type.enum';
import { initialState } from './form-state.context';
import { FormStateProviderAction } from './interfaces/form-state-provider-action.interface';
import { FormState } from './interfaces/form-state.interface';

export const FormStateReducer: Reducer<FormState, FormStateProviderAction> = (
  formState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case FormStateProviderActionType.RESET_STATE:
      return initialState;

    case FormStateProviderActionType.SET_FORM:
      return { ...formState, form: payload };

    case FormStateProviderActionType.SET_FORM_ID:
      return { ...formState, formId: payload };

    case FormStateProviderActionType.SET_FORM_PROCEDURE:
      return { ...formState, formProcedure: payload };

    case FormStateProviderActionType.SET_FORM_PROCEDURE_NAME:
      return { ...formState, formProcedureName: payload };

    default:
      return formState;
  }
};
